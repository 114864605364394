import styled from 'styled-components'
import { window } from 'browser-monads-ts'
import { navigate } from 'gatsby'

import { Heading, StyledMarkdown } from '../../atoms'

export const ToutWrapper = styled.div.attrs(({ 'data-href': postHref }) => ({
  ...postHref && {
    onClick: () => {
      if (postHref.startsWith('http')) {
        window.open(postHref, '_blank')
      } else {
        navigate(postHref)
      }
    },
  },
}))`
  ${({ theme }) => `
    width: 100%;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15), 20px 10px 20px 0 rgba(0, 0, 0, 0.15);
    background-color: ${theme.formats.light.background};
    color: ${theme.formats.light.color};
    margin-bottom: 2em;
    cursor: pointer;

    @media (min-width: ${theme.breakpoints.sm}) {
      max-width: 890px;
    }
  `}
`

export const StyledHeading = styled(Heading).attrs(() => ({
  tag: '2',
}))`
  margin-bottom: 2rem;
`

export const ToutContent = styled.div`
  ${({ theme }) => `
    margin: 2em 1.5em;

    @media (min-width: ${theme.breakpoints.sm}) {
      margin: 2.5em;
    }
  `}
`

export const ToutCategory = styled(Heading).attrs(() => ({
  tag: '5',
}))`
  margin: 0 0 1em;
  text-transform: uppercase;
`

export const ToutTitle = styled(Heading).attrs(() => ({
  tag: '4',
}))`
  margin: 0;
  font-size: 1.2em;
`

export const ToutBody = styled.div`
  margin: 1em 0 2em;
`

export const ToutAuthorWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1em;
`

export const ToutAuthor = styled.div`
  display: flex;
  flex-direction: column;

  & > p {
    font-weight: bold;
  }
`

export const ToutAuthorImage = styled.div`
  flex-shrink: 0;
  margin-right: 0.75rem;

  & > div > img {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 9999px;
  }
`

export const ToutDate = styled.div`
  display: flex;
  gap: .25em;
  opacity: .75;
  ${({ wrapped }) => !wrapped && `
    justify-content: flex-end;
    font-style: italic;
    opacity: .9;
  `}
`

export const ToutDescription = styled(StyledMarkdown)``

export const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 1em;
  gap: .25em;
`

export const ToutTag = styled.div`
  font-style: italic;
`
