import styled from 'styled-components'

import { Button } from '@zendeskgarden/react-buttons'

import { Heading } from '../../atoms'

export const Wrapper = styled.div`
  position: fixed;
  display: flex;
  width: 100%;
  z-index: +1;
  bottom: 0;
  transition: max-height 1.5s ease;
  ${({ theme, open }) => `
    max-height: ${open === true ? '30vh' : 0};

    @media (min-width: ${theme.breakpoints.lg}) {
      justify-content: center;
    }
  `}
`

export const Container = styled.div`
  ${({ theme, format }) => `
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 8px;
    min-height: 40px;
    padding: 0.5em .75em 0.5em 2em;
    margin: 0 150px .75em 150px;
    background: ${theme.colors[format]};
    max-width: ${theme.breakpoints.xl};
    @media (max-width: ${theme.breakpoints.xs}) {
      margin: 0 80px .75em 1em;
      padding: 0.5em;
      justify-content: center;
      text-align: center;
    }
  `}
`

export const BodyWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    justify-content: center;
  }
`

export const CalloutTitle = styled(Heading).attrs(({ theme }) => ({ tag: '4', weight: 400, size: theme.typography.sizes.headings.h5 }))`
  padding: 0;
  margin: 0 .5em 0 0;
`

export const CTAWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  place-content: space-evenly;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: .5em;
  }
`

export const CTATitle = styled(Heading).attrs(({ theme }) => ({ tag: '4', weight: 400, size: theme.typography.sizes.headings.h5 }))`
  padding: 0;
  margin: 0;
`

export const CTAButton = styled(Button).attrs(() => ({ isPrimary: true }))`
  height: unset;
  min-width: 14em;
  border-radius: 100px;
  font-weight: 600;
  line-height: 32px;
  margin-left: 1em;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin: .1em 0 0 .25em;
  }
`

export const IconWrapper = styled.div`
  cursor: pointer;
  width: 20px;
  height: 20px;
  padding: 6px;
`
