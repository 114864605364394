/* eslint-disable max-len */
import React, { useMemo } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import { isTrue, isType } from '@agnostack/lib-core'

import { ImageWrapper } from '../../../styles/core'
import { LinkButton, Section } from '../../atoms'
import {
  ToutWrapper,
  ToutContent,
  ToutCategory,
  ToutTitle,
  ToutBody,
  ToutDate,
  ToutDescription,
  ToutAuthorWrapper,
  ToutAuthorImage,
  ToutAuthor,
  ToutTag,
  TagsWrapper,
  StyledHeading,
} from './PostTout.style'

const ToutTags = ({ children }) => {
  const postTags = useMemo(() => (
    isType(children, 'string') ? children.split(' ') : children
  ), [children])

  return (
    <TagsWrapper>
      {postTags.map((tag, tagIndex) => (
        <ToutTag key={`post-tag-${tag}-${tagIndex}`}>
          {tag}
        </ToutTag>
      ))}
    </TagsWrapper>
  )
}

const PostTout = (renderProps) => {
  const {
    allPostsJson: {
      edges: posts,
    } = {},
  } = useStaticQuery(graphql`
    query postToutQuery {
      allPostsJson {
        edges {
          node {
            title
            href
            featured
            category
            description
            tags
            date
            datetime
            author {
              name
              href
              imageUrl
            }
            heading {
              title
              href
              text
            }
            image {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: NONE
                )
              }
            }
          }
        }
      }
    }
  `)

  const {
    featuredPost,
    headingLink,
    heading = 'agnoStack in the news',
    headingLinkText = 'View All',
  } = useMemo(() => {
    const _featuredPost = posts?.find(({ featured }) => isTrue(featured))?.node ?? posts?.[0]?.node

    return {
      featuredPost: _featuredPost,
      heading: _featuredPost?.heading?.title,
      headingLink: _featuredPost?.heading?.href,
      headingLinkText: _featuredPost?.heading?.text,
    }
  }, [posts])

  if (!featuredPost) {
    return null
  }

  return (
    <Section id="post-tout" {...renderProps}>
      <StyledHeading>{heading}</StyledHeading>
      <ToutWrapper data-href={featuredPost.href}>
        <ImageWrapper width="100%">
          <GatsbyImage
            image={featuredPost.image.childImageSharp.gatsbyImageData}
            alt={featuredPost.title}
          />
        </ImageWrapper>
        <ToutContent>
          {featuredPost.category && (
            <ToutCategory>{featuredPost.category}</ToutCategory>
          )}
          <ToutTitle>{featuredPost.title}</ToutTitle>
          <ToutBody>
            <ToutDescription>
              {featuredPost.description}
            </ToutDescription>
            {featuredPost.tags && (
              <ToutTags>
                {featuredPost.tags}
              </ToutTags>
            )}
            {(featuredPost.author?.name || featuredPost.date) && (
              featuredPost.author?.name ? (
                <ToutAuthorWrapper>
                  {featuredPost.author.imageUrl && (
                    <ToutAuthorImage>
                      <div>
                        <img src={featuredPost.author.imageUrl} alt={featuredPost.author.name} />
                      </div>
                    </ToutAuthorImage>
                  )}
                  <ToutAuthor>
                    <p>
                      {featuredPost.author.name}
                    </p>
                    {featuredPost.date && (
                      <ToutDate wrapped>
                        <time dateTime={featuredPost.datetime}>{featuredPost.date}</time>
                        <span aria-hidden="true">&middot;</span>
                        {featuredPost.readingTime && (
                          <>
                            <span>{featuredPost.readingTime}</span>
                            <span> read</span>
                          </>
                        )}
                      </ToutDate>
                    )}
                  </ToutAuthor>
                </ToutAuthorWrapper>
              ) : (
                <ToutDate>
                  <span aria-hidden="true">&middot;</span>
                  <time dateTime={featuredPost.datetime}>{featuredPost.date}</time>
                  {featuredPost.readingTime && (
                    <>
                      <span>{featuredPost.readingTime}</span>
                      <span> read</span>
                    </>
                  )}
                </ToutDate>
              )
            )}
          </ToutBody>
        </ToutContent>
      </ToutWrapper>
      {headingLink && (
        <LinkButton format="dark" alt={heading} href={headingLink} target="_blank">
          {headingLinkText}
        </LinkButton>
      )}
    </Section>
  )
}

export default PostTout
