/* eslint-disable max-len */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Row, Col } from '@zendeskgarden/react-grid'

import { ScheduleButton } from '../../atoms'
import {
  StyledSection,
  StyledGrid,
  StyledHeading,
  StyledBody,
  StyledCol,
  StyledImageWrapper,
} from './FeaturesBar.style'

const FeaturesBar = (renderProps) => {
  const data = useStaticQuery(graphql`
    query featuresBarQuery {
      laptopImage: file(relativePath: {eq: "website-laptop.png"}) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: NONE
          )
        }
      }
    }
  `)

  const { laptopImage } = data

  return (
    <StyledSection {...renderProps}>
      <StyledGrid id="features">
        <Row>
          <StyledCol lg={6}>
            <StyledHeading>
              agnoStack seamlessly integrates commerce with customer service.
            </StyledHeading>
            <StyledBody>
              <p>
                <span>With just a few clicks, support agents can now quickly access and search order history, view real-time shipping and payment status, modify orders, issue refunds and much more - without ever leaving Zendesk</span>
                <sup>Ⓡ</sup>
                <span>.</span>
              </p>
              <br />
              <p>
                <span>Get started today with a free trial to our&nbsp;</span>
                <a href="/pricing#starter">Starter</a>
                <span>,&nbsp;</span>
                <a href="/pricing#professional">Professional</a>
                <span>,&nbsp;or&nbsp;</span>
                <a href="/pricing#enterprise">Enterprise</a>
                <span>&nbsp;plan to quickly improve your customer&apos;s post-purchase experience.</span>
              </p>
            </StyledBody>
            <ScheduleButton>Request a Demo</ScheduleButton>
          </StyledCol>
          <Col lg={6}>
            <StyledImageWrapper>
              <GatsbyImage image={laptopImage.childImageSharp.gatsbyImageData} alt="What we do" />
            </StyledImageWrapper>
          </Col>
        </Row>
      </StyledGrid>
    </StyledSection>
  )
}

export default FeaturesBar
