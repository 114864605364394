import styled from 'styled-components'
import { Grid, Col } from '@zendeskgarden/react-grid'

import { ImageWrapper } from '../../../styles/core'
import { Section, Heading } from '../../atoms'

export const StyledGrid = styled(Grid)`
  div:nth-of-type(odd) {
    flex-direction: row-reverse;
  }

  ${({ theme }) => `
    margin-bottom: -20px;

    @media (min-width: ${theme.breakpoints.lg}) {
      margin-top: 50px;
      margin-bottom: -50px;
    }
  `}
`

export const StyledSection = styled(Section)`
  padding-bottom: 0;
`

export const StyledCol = styled(Col)`
  ${({ theme }) => `
    padding-bottom: 20px;

    @media (min-width: ${theme.breakpoints.lg}) {
      padding-bottom: 100px;
    }
  `}
`

export const StyledHeading = styled(Heading).attrs(() => ({
  tag: '3',
}))`
  ${({ theme }) => `
    font-size: 1.5em;
    line-height: 1.1em;
    padding-bottom: 0.5em;
    padding-right: 0;

    @media (min-width: ${theme.breakpoints.sm}) {
      padding-right: 3em;
      font-size: 2em;
    }

    @media (min-width: ${theme.breakpoints.lg}) {
      padding-right: 3em;
    }
  `}
`

export const StyledBody = styled.div`
  ${({ theme }) => `
    padding-bottom: 2em;

    @media (min-width: ${theme.breakpoints.sm}) {
      padding-bottom: 3em;
      padding-right: 3em;
    }
  `}
`

export const StyledImageWrapper = styled(ImageWrapper)`
  ${({ theme }) => `
    width: 100%;
    padding-top: 2em;

    @media (min-width: ${theme.breakpoints.lg}) {
      padding-top: 0;
      width: 80%;
    }
  `}
`
